import React, { useState } from "react";

import ContactIcon from "../../../../assets/PickUp/Contact.svg";
import WebContactIcon from "../../../../assets/PickUp/WebContact.svg";
import WarehouseIcon from "../../../../assets/PickUp/Warehouse.svg";
import OfficeIcon from "../../../../assets/PickUp/Office.svg";
import InfoCircle from "../../../../assets/info-circle.svg";

import CustomInputBox from "../../../../components/Input";

interface IContactDetailsProps {
  data: {
    pickupAddress: any;
    setPickupAddress: any;
    contactLabel: string;
    inputError: boolean;
    setInputError?: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

interface ValidationErrorState {
  name: string | null;
  mobileNo: string | null;
  emailId: string | null;
  alternateMobileNo: string | null;
}

const ContactDetails: React.FunctionComponent<IContactDetailsProps> = ({
  data: {
    pickupAddress,
    setPickupAddress,
    contactLabel,
    inputError,
    setInputError,
  },
}) => {
  const [validationErrors, setValidationErrors] =
    useState<ValidationErrorState>({
      name: null,
      mobileNo: null,
      emailId: null,
      alternateMobileNo: null,
    });

  const address =
    contactLabel === "Return Address Contact"
      ? pickupAddress?.returnAddress?.contact
      : pickupAddress?.pickupAddress?.contact;
  const handleContactChange = (
    fieldName: keyof typeof address,
    value: string
  ) => {
    const addressName: string =
      contactLabel === "Return Address Contact"
        ? "returnAddress"
        : "pickupAddress";
    setPickupAddress((prevData: any) => ({
      ...prevData,
      [addressName]: {
        ...prevData[addressName],
        contact: {
          ...prevData[addressName].contact,
          [fieldName]: value,
        },
      },
    }));
  };

  const setValidationError = (
    fieldName: keyof ValidationErrorState,
    error: string | null
  ) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const validateName = (name: string) => {
    if (/^\D+$/.test(name) || name === "") {
      return null;
    } else {
      return "Name should not contain numbers";
    }
  };

  const validateMobileNo = (mobileNo: string) => {
    const numericValue = mobileNo.replace(/[^0-9]/g, "");
    if (numericValue.length === 10 || numericValue.length === 0) {
      return null;
    } else {
      return "Mobile number must be a 10-digit number";
    }
  };

  const validateEmailId = (emailId: string) => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailId) || emailId === "") {
      return null;
    } else {
      return "Invalid email address";
    }
  };

  const validateAlternateMobileNo = (alternateMobileNo: string) => {
    const numericValue = alternateMobileNo.replace(/[^0-9]/g, "");
    if (numericValue.length === 10 || numericValue.length === 0) {
      return null;
    } else {
      return "Mobile number must be a 10-digit number";
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center gap-2  lg:col-span-3 mb-5 lg:mb-[23px]">
        <img src={ContactIcon} alt="Contact" className="lg:hidden" />
        <img src={WebContactIcon} alt="Contact" className="hidden lg:block" />

        <p className="text-[18px] font-Lato lg:text-[24px] lg:font-Lato lg:text-[#323232]">
          {contactLabel}
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="mb-4 lg:mb-6 lg:mr-6">
          <CustomInputBox
            inputType="text"
            label="Name of the contact person"
            value={address.name}
            onChange={(e) => {
              const nameValue = e.target.value;
              handleContactChange("name", nameValue);
              setValidationError("name", validateName(nameValue));
            }}
            inputError={inputError}
            name="name-contact-person"
          />
          {validationErrors.name && (
            <div className="flex items-center gap-x-1 mt-1">
              <img src={InfoCircle} alt="" width={10} height={10} />
              <span className="font-normal text-[#F35838] text-xs leading-3">
                {validationErrors.name}
              </span>
            </div>
          )}
        </div>

        <div className="mb-4 lg:mb-6 lg:mr-6">
          <CustomInputBox
            label="Mobile Number"
            value={address.mobileNo}
            maxLength={10}
            inputMode="numeric"
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9]/g, "");
              handleContactChange("mobileNo", numericValue);
              setValidationError("mobileNo", validateMobileNo(numericValue));
              if (setInputError) {
                setInputError(false);
              }
            }}
            inputError={inputError}
            name="mobile-number-contact-person"
          />
          {inputError && validationErrors.mobileNo && (
            <div className="flex items-center gap-x-1 mt-1">
              <img src={InfoCircle} alt="" width={10} height={10} />
              <span className="font-normal text-[#F35838] text-xs leading-3">
                {validationErrors.mobileNo}
              </span>
            </div>
          )}
        </div>

        <div className="mb-4 lg:mb-6 lg:mr-6">
          <CustomInputBox
            inputType="email"
            label="Email ID (optional)"
            value={address.emailId}
            onChange={(e) => {
              const emailValue = e.target.value;
              handleContactChange("emailId", emailValue);
              setValidationError("emailId", validateEmailId(emailValue));
              if (setInputError) {
                setInputError(false);
              }
            }}
            // inputError={inputError}
            name="email-id-contact-person"
          />
          {inputError && validationErrors.emailId && (
            <div className="flex items-center gap-x-1 mt-1">
              <img src={InfoCircle} alt="" width={10} height={10} />
              <span className="font-normal text-[#F35838] text-xs leading-3">
                {validationErrors.emailId}
              </span>
            </div>
          )}
        </div>
        <div className="mb-7 lg:mb-6 lg:mr-6">
          <CustomInputBox
            label="Alternate mobile number (optional)"
            value={address.alternateMobileNo ? address.alternateMobileNo : ""}
            maxLength={10}
            inputMode="numeric"
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9]/g, "");
              handleContactChange("alternateMobileNo", numericValue);
              setValidationError(
                "alternateMobileNo",
                validateAlternateMobileNo(numericValue)
              );
            }}
            name="alternate-mobile-number-contact-person"
          />
          {validationErrors.alternateMobileNo && (
            <div className="flex items-center gap-x-1 mt-1">
              <img src={InfoCircle} alt="" width={10} height={10} />
              <span className="font-normal text-[#F35838] text-xs leading-3">
                {validationErrors.alternateMobileNo}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="lg:col-span-3  mb-3 lg:mb-[18px]">
        <p className="text-[#202427] text-[18px] font-Lato lg:font-Lato lg:text-[20px] lg:text-[#323232] ">
          Save your contact as
        </p>
      </div>

      <div className="flex flex-nowrap customScroll space-x-4 lg:col-span-3 mb-7 ">
        <div
          className={`flex flex-row justify-center text-[16px] items-center gap-[8px] border-[0.5px]   rounded bg-[#FEFEFE] cursor-pointer lg:h-[35px] py-2 px-4   ${
            address.type === "shopkeeper"
              ? "border-[#004EFF] text-[#004EFF] "
              : "border-gray-300 text-[#1C1C1C]"
          }`}
          onClick={(e) => {
            handleContactChange("type", "shopkeeper");
          }}
          id="shopkeeper-contact-person"
        >
          <img src={OfficeIcon} alt="ShopKeeper" />
          <p className="lg:font-semibold lg:font-Open lg:text-[14px] ">
            Shopkeeper
          </p>
        </div>

        <div
          className={`flex flex-row justify-center text-[16px] items-center gap-[8px] border-[0.5px]   rounded bg-[#FEFEFE] cursor-pointer lg:h-[35px] py-2 px-4   whitespace-nowrap ${
            address.type === "warehouse associate"
              ? "border-[#004EFF] text-[#004EFF] "
              : "border-gray-300 text-[#1C1C1C]"
          }`}
          onClick={() => {
            handleContactChange("type", "warehouse associate");
          }}
          id="warehouse-associate-contact-person"
        >
          <img src={WarehouseIcon} alt="Warehouse associate" />
          <p className="lg:font-semibold lg:font-Open  lg:text-[14px] ">
            Warehouse associate
          </p>
        </div>

        <div
          className={`flex flex-row justify-center text-[16px] items-center gap-[8px] border-[0.5px]   rounded bg-[#FEFEFE] cursor-pointer lg:h-[35px] py-2 px-4   whitespace-nowrap ${
            address.type === "dispatcher"
              ? "border-[#004EFF] text-[#004EFF] "
              : "border-gray-300 text-[#1C1C1C]"
          }`}
          onClick={() => {
            handleContactChange("type", "dispatcher");
          }}
          id="dispatcher-contact-person"
        >
          <img src={WarehouseIcon} alt="Warehouse associate" />
          <p className="lg:font-semibold lg:font-Open  lg:text-[14px] ">
            Dispatcher
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
