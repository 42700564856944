import ProfileImage from "../assets/ReturningUser/profileIcon.svg";
import EditImage from "../assets/PickUp/Edit.svg";
import PhoneIcon from "../assets/ReturningUser/phoneIcon.svg";
import locationImage from "../assets/Location.png";
import ItemIcon from "../assets/Product/Item.svg";
import ProImage from "../assets/ReturningUser/Product.svg";
import ShopifyIcon from "../assets/Catalogue/shopify.svg";
import AmazonIcon from "../assets/Catalogue/amazon.svg";
import SalesforceIcon from "../assets/Catalogue/salesforce.svg";
import FingerIcon from "../assets/Catalogue/finger.svg";
import WooIcon from "../assets/Catalogue/woo.svg";
import MeeshoIcon from "../assets/Catalogue/meesho.svg";
import DownWardArrow from "../assets/downwardArrow.svg";
import ShopifyLg from "../assets/Catalogue/shopifyLg.svg";
import MagentoLg from "../assets/Catalogue/MagentoLg.svg";
import OpenCartLg from "../assets/Catalogue/OpenCartLg.svg";
import PrestashopLg from "../assets/Catalogue/PrestashopLg.svg";
import SquareSpaceLg from "../assets/Catalogue/SquareSpaceLg.svg";
import WixLg from "../assets/Catalogue/WixLg.svg";
import WooCommerceLg from "../assets/Catalogue/WooCommerceLg.svg";
import instamojoLg from "../assets/Catalogue/instamojoLg.svg";
import EditImageWithBlack from "../assets/Catalogue/edit.svg";
import FashionIcon from "../assets/Product/Product (3).svg";
import ElectronicsIcon from "../assets/Product/Item.svg";
import LifestyleIcon from "../assets/Product/watch.svg";
import SportsIcon from "../assets/Product/sports.svg";
import FitnessIcon from "../assets/Product/fitness.svg";
import GiftIcon from "../assets/Product/gift.svg";
import ForkIcon from "../assets/Product/ForkKnife.svg";
import ZohoIcon from "../assets/Catalogue/ZOHO.svg.png";
import AmazonPngIcon from "../assets/AmazonIcon.png";
import UniCommerceIcon from "../assets/Catalogue/unicommerce fn.svg";

export const insufficientBalance = [
  {
    header: "Insufficient  balance",
    header2: "RECHARGE",
    orders: "04 Orders",
    nameOfTheOrder: "Name of the order",
    recharge: "Recharge",
  },
  {
    header: "Duplicate ID",
    header2: "FIX",
    orders: "24 Orders",
  },
  {
    header: "Landmark not found",
    header2: "FIX",
    orders: "24 Orders",
  },
  {
    header: "Landmark not found",
    header2: "FIX",
    orders: "24 Orders",
  },
  {
    header: "Low address accuracy",
    header2: "FIX",
    orders: "24 Orders",
  },
  {
    header: "High risk customer",
    header2: "FIX",
    orders: "24 Orders",
  },
];

export const deliveryAddress = [
  {
    header: "Name",
    headerText: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, sector 8, Western express way Andheri, Mumbai, Maharastra 4220112",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Name",
    headerText: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, sector 8, Western express way Andheri, Mumbai, Maharastra 4220112",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Name",
    headerText: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, sector 8, Western express way Andheri, Mumbai, Maharastra 4220112",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Name",
    headerText: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, sector 8, Western express way Andheri, Mumbai, Maharastra 4220112",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Name",
    headerText: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, sector 8, Western express way Andheri, Mumbai, Maharastra 4220112",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Name",
    headerText: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, sector 8, Western express way Andheri, Mumbai, Maharastra 4220112",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
];

export const pickupAddress = [
  {
    header: "Warehouse",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, Sector 8, Western express way, Andheri, Mumbai, Maharastra 4220112",
    ProfileImage: ProfileImage,
    ProfileName: "Anish Sharma",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, Sector 8, Western express way, Andheri, Mumbai, Maharastra 4220112",
    ProfileImage: ProfileImage,
    ProfileName: "Anish Sharma",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Warehouse",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, Sector 8, Western express way, Andheri, Mumbai, Maharastra 4220112",
    ProfileImage: ProfileImage,
    ProfileName: "Anish Sharma",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, Sector 8, Western express way, Andheri, Mumbai, Maharastra 4220112",
    ProfileImage: ProfileImage,
    ProfileName: "Anish Sharma",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Warehouse",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, Sector 8, Western express way, Andheri, Mumbai, Maharastra 4220112",
    ProfileImage: ProfileImage,
    ProfileName: "Anish Sharma",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
  {
    header: "Warehouse Mumbai",
    editImage: EditImageWithBlack,
    address:
      "Plot No 12, Sector 8, Western express way, Andheri, Mumbai, Maharastra 4220112",
    ProfileImage: ProfileImage,
    ProfileName: "Anish Sharma",
    PhoneIcon: PhoneIcon,
    PhoneNumber: "+91 12345 12345",
  },
];

export const productCardQuantity = [
  {
    recommended: true,
    proImage: ProImage,
    header: "Mac book air + Airpodes + Charger",
    text: "5 kg | ₹500",
  },
];

export const productData = [
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
];

export const productCatalogueData = [
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
  {
    itemImage: ItemIcon,
    header: "Mac book air",
    text: "5 kg | 12 x 12 x 12 cm",
  },
];

export const customServiceData = [
  {
    serviceType: "Economy",
    servicePrice: "₹ 2300 | ",
    serviceSpan: " Save ₹100 using this service",
    serviceETA: "ETA: 24 Jun 23",
  },
  {
    serviceType: "Standard",
    servicePrice: "₹ 2300 | ",
    serviceSpan: " Save ₹100 using this service",
    serviceETA: "ETA: 23 Jun 23",
  },
  {
    serviceType: "Premium",
    servicePrice: "₹ 2300 | ",
    serviceSpan: " Save ₹100 using this service",
    serviceETA: "ETA: 24 Jun 23",
  },
];

export const customDeliveryData = [
  {
    locationImage: locationImage,
    header: "Your top delivery location",
    editImage: EditImage,
    text: "Mumbai Warehouse",
    address: "ICICI Bank, Andheri, Mumbai, Maharastra 422012",
  },
  {
    locationImage: locationImage,
    header: "Your top delivery location",
    editImage: EditImage,
    text: "Mumbai Warehouse",
    address: "ICICI Bank, Andheri, Mumbai, Maharastra 422012",
  },
  {
    locationImage: locationImage,
    header: "Your top delivery location",
    editImage: EditImage,
    text: "Mumbai Warehouse",
    address: "ICICI Bank, Andheri, Mumbai, Maharastra 422012",
  },
];

export const customPickUpData = [
  {
    locationImage: locationImage,
    header: "Your top pickup location",
    editImage: EditImage,
    text: "Jhindal Warehouse",
    address:
      "Plot no. ICICI Bank, Andheri link road Andheri, Mumbai, Maharastra 422012",
  },
  {
    locationImage: locationImage,
    header: "Your top pickup location",
    editImage: EditImage,
    text: "Jhindal Warehouse",
    address: "ICICI Bank, Andheri,Mumbai, Maharastra 422012",
  },
  {
    locationImage: locationImage,
    header: "Your top pickup location",
    editImage: EditImage,
    text: "Jhindal Warehouse",
    address: "ICICI Bank, Andheri, Mumbai, Maharastra 422012",
  },
];

export const customContactData = [
  {
    profileImage: ProfileImage,
    profileImage1: ProfileImage,
    header: "Your top contact person",
    editImage: EditImage,
    role: "Warehouse Associate",
    personName: "Anish Sharma",
    phoneIcon: PhoneIcon,
    personContact: "9112345123",
  },
];

export const ReturnContactData = [
  {
    profileImage: "",
    profileImage1: "",
    header: "",
    editImage: "",
    role: "",
    personName: "",
    phoneIcon: "",
    personContact: "",
  },
];

export const standardServiceData = [
  {
    serviceType: "Economy",
    price: 2300,
    savedPrice: 100,
    etaDate: "02 JUN 2023",
  },
  {
    serviceType: "Standard",
    price: 2300,
    savedPrice: 100,
    etaDate: "02 JUN 2023",
  },
  {
    serviceType: "Premium",
    price: 2300,
    savedPrice: 100,
    etaDate: "02 JUN 2023",
  },
];

export const RecommendedServiceData = [
  {
    isRecommendation: true,
    recommendation: "Cheapest",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "1",
  },
  {
    isRecommendation: true,
    recommendation: "Fastest",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "2",
  },
];

export const FilterServiceData = [
  {
    isRecommendation: false,
    recommendation: "Recipient",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "3",
  },
  {
    isRecommendation: false,
    recommendation: "Recipient",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "4",
  },
  {
    isRecommendation: false,
    recommendation: "Recipient",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "5",
  },

  {
    isRecommendation: false,
    recommendation: "Recipient",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "5",
  },
  {
    isRecommendation: false,
    recommendation: "Recipient",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "5",
  },

  {
    isRecommendation: false,
    recommendation: "Recipient",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "5",
  },
  {
    isRecommendation: false,
    recommendation: "Recipient",
    courierPartner: "DHL",
    serviceType: "Economy",
    weight: 1,
    totalPrice: 2300,
    savePrice: 200,
    etaDate: "24 Jun 23",
    name: "service",
    value: "5",
  },
];

export const dummyDayData = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Tomorrow",
    value: "tomorrow",
  },
  {
    label: "Schedule",
    value: "schedule",
  },
];

export const dummyTimeData = [
  {
    label: "11:00 AM - 12:00 PM",
    value: "11:00 AM - 12:00 PM",
  },
  {
    label: "12:00 PM - 01:00 PM",
    value: "12:00 PM - 01:00 PM",
  },
  {
    label: "01:00 PM - 02:00 PM",
    value: "01:00 PM - 02:00 PM",
  },
  {
    label: "02:00 PM - 03:00 PM",
    value: "02:00 PM - 03:00 PM",
  },
  {
    label: "03:00 PM - 04.00 PM",
    value: "03:00 PM - 04.00 PM",
  },
  {
    label: "04:00 PM - 05.00 PM",
    value: "04:00 PM - 05.00 PM",
  },
  {
    label: "05:00 PM - 06.00 PM",
    value: "05:00 PM - 06.00 PM",
  },
];

export const dummyTransactionFilterData = [
  {
    name: "Date",
    isCollapse: false,
    menu: [
      {
        name: "Today",

        isActive: false,
      },
      {
        name: "This Week",

        isActive: false,
      },
      {
        name: "This Month",

        isActive: false,
      },
      {
        name: "This Quarter",

        isActive: false,
      },
      {
        name: "This Year",

        isActive: false,
      },
    ],
  },

  {
    name: "Credited",

    isCollapse: false,
    menu: [
      {
        name: "Below ₹500",

        isActive: false,
      },
      {
        name: "Between ₹500-1000",

        isActive: false,
      },
      {
        name: "Between ₹1000-2000",

        isActive: false,
      },

      {
        name: "Between ₹2000-3000",

        isActive: false,
      },
      {
        name: "Above ₹3000",

        isActive: false,
      },
    ],
  },

  {
    name: "Debited",

    isCollapse: false,
    menu: [
      {
        name: "Below ₹500",

        isActive: false,
      },
      {
        name: "Between ₹500-1000",

        isActive: false,
      },
      {
        name: "Between ₹1000-2000",

        isActive: false,
      },

      {
        name: "Between ₹2000-3000",

        isActive: false,
      },
      {
        name: "Above ₹3000",

        isActive: false,
      },
    ],
  },

  {
    name: "Status",

    isCollapse: false,
    menu: [
      {
        name: "Approved",

        isActive: false,
      },
      {
        name: "Requested",

        isActive: false,
      },

      {
        name: "Declined",

        isActive: false,
      },
    ],
  },
];

export const ChannelIntegrationCarts = {
  title: "Carts",
  channels: [
    {
      name: "STORE_NAME",
      icon: ShopifyIcon,
      iconLg: ShopifyLg,
      integrated: false,
      storesIntegrated: 5,
      channel: "SHOPIFY",
    },
    {
      name: "STORE_NAME",
      icon: WooIcon,
      iconLg: WooCommerceLg,
      integrated: false,
      storesIntegrated: 5,
      channel: "WOOCOMMERCE",
    },
    {
      name: "STORE_NAME",
      icon: ZohoIcon,
      iconLg: ZohoIcon,
      integrated: false,
      storesIntegrated: 5,
      channel: "ZOHO",
    },
    // {
    //   name: "STORE_NAME",
    //   icon: UniCommerceIcon,
    //   iconLg: UniCommerceIcon,
    //   integrated: false,
    //   storesIntegrated: 5,
    //   channel: "UNICOMMERCE",
    // },
    // {
    //   name: "STORE_NAME",
    //   icon: AmazonPngIcon,
    //   iconLg: AmazonPngIcon,
    //   integrated: false,
    //   storesIntegrated: 5,
    //   channel: "AMAZON",
    // },
    // {
    //   name: "Woo",
    //   icon: WooIcon,
    //   iconLg: ShopifyLg,
    //   integrated: true,
    //   storesIntegrated: 5,
    // },
    // {
    //   name: "Shopify",
    //   icon: ShopifyIcon,
    //   iconLg: ShopifyLg,
    // },
    // {
    //   name: "Woo",
    //   icon: WooIcon,
    //   iconLg: ShopifyLg,
    // },
    // {
    //   name: "Woo",
    //   icon: WooIcon,
    //   iconLg: ShopifyLg,
    // },
    // {
    //   name: "Shopify",
    //   icon: ShopifyIcon,
    //   iconLg: ShopifyLg,
    // },
    // {
    //   name: "Woo",
    //   icon: WooIcon,
    //   iconLg: ShopifyLg,
    // },
    // {
    //   name: "Woo",
    //   icon: WooIcon,
    //   iconLg: ShopifyLg,
    // },
  ],
};

export const ChannelIntegrationMarketPlace = {
  title: "Marketplaces",
  channels: [
    {
      name: "Amazon",
      icon: AmazonIcon,
      iconLg: WooCommerceLg,
      integrated: false,
    },
    {
      name: "Meesho",
      icon: MeeshoIcon,
      iconLg: WooCommerceLg,
      integrated: false,
    },
    {
      name: "Amazon",
      icon: AmazonIcon,
      iconLg: WooCommerceLg,
      integrated: false,
    },
    {
      name: "Meesho",
      icon: MeeshoIcon,
      iconLg: WooCommerceLg,
      integrated: false,
    },
    {
      name: "Amazon",
      icon: AmazonIcon,
      iconLg: WooCommerceLg,
      integrated: false,
    },
    {
      name: "Meesho",
      icon: MeeshoIcon,
      iconLg: WooCommerceLg,
      integrated: false,
    },
  ],
};

export const ChannelIntegrationWarehouse = {
  title: "Warehouse Management System",
  channels: [
    {
      name: "Name",
      icon: FingerIcon,
      integrated: false,
    },
    {
      name: "Shopify",
      icon: ShopifyIcon,
      integrated: false,
    },
  ],
};

export const ChannelIntegrationInventory = {
  title: "Inventory Management System",
  channels: [
    {
      name: "Shopify",
      icon: ShopifyIcon,
      integrated: false,
      iconLg: ShopifyLg,
    },
  ],
};

export const dummyChannelIntegrationData = [
  {
    title: "Carts",
    channels: [
      {
        name: "Shopify",
        icon: ShopifyIcon,
        iconLg: ShopifyLg,
        integrated: true,
        storesIntegrated: 5,
      },
      {
        name: "Woo",
        icon: WooIcon,
        iconLg: ShopifyLg,
        integrated: true,
        storesIntegrated: 5,
      },
      {
        name: "Woo",
        icon: WooIcon,
        iconLg: ShopifyLg,
        integrated: true,
        storesIntegrated: 5,
      },
      {
        name: "Shopify",
        icon: ShopifyIcon,
        iconLg: ShopifyLg,
      },
      {
        name: "Woo",
        icon: WooIcon,
        iconLg: ShopifyLg,
      },
      {
        name: "Woo",
        icon: WooIcon,
        iconLg: ShopifyLg,
      },
      {
        name: "Shopify",
        icon: ShopifyIcon,
        iconLg: ShopifyLg,
      },
      {
        name: "Woo",
        icon: WooIcon,
        iconLg: ShopifyLg,
      },
      {
        name: "Woo",
        icon: WooIcon,
        iconLg: ShopifyLg,
      },
    ],
  },

  {
    title: "Marketplaces",
    channels: [
      {
        name: "Amazon",
        icon: AmazonIcon,
        iconLg: WooCommerceLg,
        integrated: false,
      },
      {
        name: "Meesho",
        icon: MeeshoIcon,
        iconLg: WooCommerceLg,
        integrated: false,
      },
      {
        name: "Amazon",
        icon: AmazonIcon,
        iconLg: WooCommerceLg,
        integrated: false,
      },
      {
        name: "Meesho",
        icon: MeeshoIcon,
        iconLg: WooCommerceLg,
        integrated: false,
      },
      {
        name: "Amazon",
        icon: AmazonIcon,
        iconLg: WooCommerceLg,
        integrated: false,
      },
      {
        name: "Meesho",
        icon: MeeshoIcon,
        iconLg: WooCommerceLg,
        integrated: false,
      },
    ],
  },

  {
    title: "Order Management System",
    channels: [
      {
        name: "Salesforce",
        icon: SalesforceIcon,
        iconLg: WixLg,
        integrated: false,
      },
      {
        name: "Shopify",
        icon: ShopifyIcon,
        iconLg: WixLg,
        integrated: false,
      },
    ],
  },

  {
    title: "Warehouse Management System",
    channels: [
      {
        name: "Name",
        icon: FingerIcon,
        integrated: false,
      },
      {
        name: "Shopify",
        icon: ShopifyIcon,
        integrated: false,
      },
    ],
  },

  {
    title: "Inventory Management System",
    channels: [
      {
        name: "Salesforce",
        icon: SalesforceIcon,
        integrated: false,
      },
      {
        name: "Shopify",
        icon: ShopifyIcon,
        integrated: false,
      },
    ],
  },
];

// export const dummyChannelBothIntegrationData = [
//   {
//     mobile : [
//     {
//       title: "Carts",
//       channels: [
//         {
//           name: "Shopify",
//           icon: ShopifyIcon,
//           integrated: true,
//           storesIntegrated: 5,
//         },
//         {
//           name: "Woo",
//           icon: WooIcon,
//           integrated: true,
//           storesIntegrated: 5,
//         },
//       ],
//     },

//     {
//       title: "Marketplace",
//       channels: [
//         {
//           name: "Amazon",
//           icon: AmazonIcon,
//           integrated: false,
//         },
//         {
//           name: "Meesho",
//           icon: MeeshoIcon,
//           integrated: false,
//         },
//       ],
//     },

//     {
//       title: "Order Management System",
//       channels: [
//         {
//           name: "Salesforce",
//           icon: SalesforceIcon,
//           integrated: false,
//         },
//         {
//           name: "Shopify",
//           icon: ShopifyIcon,
//           integrated: false,
//         },
//       ],
//     },

//     {
//       title: "Warehouse Management System",
//       channels: [
//         {
//           name: "Name",
//           icon: FingerIcon,
//           integrated: false,
//         },
//         {
//           name: "Shopify",
//           icon: ShopifyIcon,
//           integrated: false,
//         },
//       ],
//     },

//     {
//       title: "Inventory Management System",
//       channels: [
//         {
//           name: "Salesforce",
//           icon: SalesforceIcon,
//           integrated: false,
//         },
//         {
//           name: "Shopify",
//           icon: ShopifyIcon,
//           integrated: false,
//         },
//       ],
//     },
//   ],
//   web : [
//     {
//       title : "Shopify",
//       icon : ShopifyLg,
//       integrated: true,
//       storesIntegrated: 5
//     },
//     {
//       title : "WooCommerce",
//       icon : WooCommerceLg,
//       integrated: true,
//       storesIntegrated: 0
//     },
//     {
//       title : "WooCommerce",
//       icon : WooCommerceLg,
//       integrated: true,
//       storesIntegrated: 0
//     },
//     {
//       title : "WooCommerce",
//       icon : WooCommerceLg,
//       integrated: true,
//       storesIntegrated: 0
//     },
//     {
//       title : "WooCommerce",
//       icon : WooCommerceLg,
//       integrated: true,
//       storesIntegrated: 0
//     },
//     {
//       title : "WooCommerce",
//       icon : WooCommerceLg,
//       integrated: true,
//       storesIntegrated: 0
//     },
//     {
//       title : "WooCommerce",
//       icon : WooCommerceLg,
//       integrated: true,
//       storesIntegrated: 0
//     },
//     {
//       title : "WooCommerce",
//       icon : WooCommerceLg,
//       integrated: true,
//       storesIntegrated: 0
//     },
//   ] }

// ]
export const dummyPickupDropdownData = [
  {
    label: "Select/ type exact landmark",
    value: "select",
  },
  {
    label: "Andheri metro station",
    value: "andheriMetroStation",
  },
  {
    label: "Bus stop",
    value: "busStop",
  },
  {
    label: "AIO Gardens",
    value: "aioGardens",
  },
  {
    label: "Jogeshwari Caves",
    value: "jogeshwariCaves",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const dummyStateDropdownData = [
  {
    label: "",
    value: "",
  },
  {
    label: "Andhra Pradesh",
    value: "Andhra pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal pradesh",
  },
  {
    label: "Assam",
    value: "Assam",
  },
  {
    label: "Bihar",
    value: "Bihar",
  },
  {
    label: "Delhi",
    value: "Delhi",
  },
  {
    label: "Chhattisgarh",
    value: "Chhattisgarh",
  },
  {
    label: "Goa",
    value: "Goa",
  },
  {
    label: "Gujarat",
    value: "Gujarat",
  },
  {
    label: "Haryana",
    value: "Haryana",
  },
  {
    label: "Himachal Pradesh",
    value: "Himachal pradesh",
  },
  {
    label: "Jharkhand",
    value: "Jharkhand",
  },
  {
    label: "Karnataka",
    value: "Karnataka",
  },
  {
    label: "Kerala",
    value: "Kerala",
  },
  {
    label: "Madhya Pradesh",
    value: "Madhya pradesh",
  },
  {
    label: "Maharashtra",
    value: "Maharashtra",
  },
  {
    label: "Manipur",
    value: "Manipur",
  },
  {
    label: "Meghalaya",
    value: "Meghalaya",
  },
  {
    label: "Mizoram",
    value: "Mizoram",
  },
  {
    label: "Nagaland",
    value: "Nagaland",
  },
  {
    label: "Odisha",
    value: "Odisha",
  },
  {
    label: "Punjab",
    value: "Punjab",
  },
  {
    label: "Rajasthan",
    value: "Rajasthan",
  },
  {
    label: "Sikkim",
    value: "Sikkim",
  },
  {
    label: "Tamil Nadu",
    value: "Tamil nadu",
  },
  {
    label: "Telangana",
    value: "Telangana",
  },
  {
    label: "Tripura",
    value: "Tripura",
  },
  {
    label: "Uttar Pradesh",
    value: "Uttar pradesh",
  },
  {
    label: "Uttarakhand",
    value: "Uttarakhand",
  },
  {
    label: "West Bengal",
    value: "West bengal",
  },
  {
    label: "Jammu and Kashmir",
    value: "Jammu and kashmir",
  },
  {
    label: "Delhi",
    value: "Delhi",
  },
];

export const searchProductData = [
  {
    productId: "06b2cc68-c923-48a6-be62-a40db36b050a",
    name: "PS 5",
    category: "EV",
    qty: 1,
    currency: "INR",
    unitPrice: 40000,
    unitTax: 10000,
    measureUnit: "cm",
    length: 40,
    breadth: 32,
    height: 40,
    deadWeight: 1,
    weightUnit: "kg",
    volumetricWeight: 10.24,
    appliedWeight: 10.24,
    divisor: 5000,
    images: [
      {
        url: "15b87eaa-6112-412d-8cf7-ee686e426fea",
        alt: "",
      },
    ],
  },

  {
    productId: "8df787dd-d859-4c30-bcfc-9457f1f6f3a8",
    name: "Mouse 1 ",
    category: "ev",
    qty: 1,
    currency: "INR",
    unitPrice: 999,
    unitTax: 99,
    measureUnit: "cm",
    length: 50,
    breadth: 20,
    height: 20,
    deadWeight: 1,
    weightUnit: "kg",
    volumetricWeight: 4,
    appliedWeight: 4,
    divisor: 5000,
    images: [
      {
        url: "c5fd4551-8af6-48c1-b251-c7d65f58f008",
        alt: "",
      },
    ],
  },
  {
    productId: "38696391-8b03-4ec1-9027-a5222445aa7b",
    name: "Bottle ",
    category: "test",
    qty: 1,
    currency: "INR",
    unitPrice: 299,
    unitTax: 20,
    measureUnit: "cm",
    length: 10,
    breadth: 20,
    height: 10,
    deadWeight: 1,
    weightUnit: "kg",
    volumetricWeight: 0.4,
    appliedWeight: 1,
    divisor: 5000,
    images: [],
  },
  {
    productId: "890f36dd-5328-4dda-b3cd-e0a07766e14a",
    name: "Earphone 20",
    category: "ev",
    qty: 1,
    currency: "INR",
    unitPrice: 2999,
    unitTax: 200,
    measureUnit: "cm",
    length: 8,
    breadth: 6,
    height: 2,
    deadWeight: 0.4,
    weightUnit: "kg",
    volumetricWeight: 0.02,
    appliedWeight: 0.4,
    divisor: 5000,
    images: [],
  },
];

export const filterItems = [
  {
    label: "Fashion",
    icon: FashionIcon,
    selected: false,
  },
  {
    label: "Electronics",
    icon: ElectronicsIcon,
    selected: false,
  },
  {
    label: "Lifestyle",
    icon: LifestyleIcon,
    selected: false,
  },
  {
    label: "Sports",
    icon: SportsIcon,
    selected: false,
  },
  {
    label: "Food and beverages",
    icon: ForkIcon,
    selected: false,
  },
  {
    label: "Fitness",
    icon: FitnessIcon,
    selected: false,
  },
  {
    label: "Gift",
    icon: GiftIcon,
    selected: false,
  },
];

export const ticketTypeOptions = [
  {
    label: "Support Type",

    selected: true,
  },
  {
    label: "Other Type",

    selected: false,
  },
];

export const ticketFilterData = [
  {
    name: "Date",

    isCollapse: false,
    menu: [
      {
        name: "Today",

        isActive: false,
      },
      {
        name: "This Week",

        isActive: false,
      },

      {
        name: "This Month",

        isActive: false,
      },
      {
        name: "This Quartar",

        isActive: false,
      },
      {
        name: "This Year",

        isActive: false,
      },
    ],
  },

  {
    name: "Support Type",

    isCollapse: false,
    menu: [
      {
        name: "Sales",

        isActive: false,
      },
      {
        name: "Account & Billing ",

        isActive: false,
      },

      {
        name: "Operational",

        isActive: false,
      },
      {
        name: "Technical",

        isActive: false,
      },
    ],
  },

  {
    name: "Priority",

    isCollapse: false,
    menu: [
      {
        name: "High",

        isActive: false,
      },
      {
        name: "Medium",

        isActive: false,
      },

      {
        name: "Low",

        isActive: false,
      },
    ],
  },

  {
    name: "Status",

    isCollapse: false,
    menu: [
      {
        name: "Processing",

        isActive: false,
      },
      {
        name: "Pending",

        isActive: false,
      },
      {
        name: "Resolved",

        isActive: false,
      },
    ],
  },
];

export const DropDownWeightData = [
  0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5,
  10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17,
  17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5,
  25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30, 30.5, 31, 31.5, 32,
  32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5,
  40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47,
  47.5, 48, 48.5, 49, 49.5, 50, 50.5, 51, 51.5, 52, 52.5, 53, 53.5, 54, 54.5,
  55, 55.5, 56, 56.5, 57, 57.5, 58, 58.5, 59, 59.5, 60, 60.5, 61, 61.5, 62,
  62.5, 63, 63.5, 64, 64.5, 65, 65.5, 66, 66.5, 67, 67.5, 68, 68.5, 69, 69.5,
  70, 70.5, 71, 71.5, 72, 72.5, 73, 73.5, 74, 74.5, 75, 75.5, 76, 76.5, 77,
  77.5, 78, 78.5, 79, 79.5, 80, 80.5, 81, 81.5, 82, 82.5, 83, 83.5, 84, 84.5,
  85, 85.5, 86, 86.5, 87, 87.5, 88, 88.5, 89, 89.5, 90, 90.5, 91, 91.5, 92,
  92.5, 93, 93.5, 94, 94.5, 95, 95.5, 96, 96.5, 97, 97.5, 98, 98.5, 99, 99.5,
  100,
];

export const serviceabilityTableData = [
  {
    add: 308.16,
    appliedWeight: 5,
    base: 34.24,
    cod: 35,
    collectableAmount: 0,
    companyServiceId: "3f5c8300",
    companyServiceName: "STANDARD 0.5",
    gst: 67.93,
    insurance: 0,
    partnerName: "BLUEDART",
    partnerServiceId: "b25c0d1f",
    partnerServiceName: "DP MODE",
    serviceMode: "SURFACE",
    total: 445.33,
    variables: 0,
    zone: "ZONE 2",
  },

  {
    add: 202.23,
    appliedWeight: 5,
    base: 25.68,
    cod: 30,
    collectableAmount: 0,
    companyServiceId: "3f5c8300",
    companyServiceName: "STANDARD 0.5",
    gst: 46.42,
    insurance: 0,
    partnerName: "DELHIVERY",
    partnerServiceId: "6ffc80bd",
    partnerServiceName: "STANDARD 0.5",
    serviceMode: "SURFACE",
    total: 304.33,
    variables: 0,
    zone: "ZONE 2",
  },
  {
    add: 442.98,
    appliedWeight: 5,
    base: 49.22,
    cod: 35,
    collectableAmount: 0,
    companyServiceId: "3f5c8300",
    companyServiceName: "STANDARD 0.5",
    gst: 94.9,
    insurance: 0,
    partnerName: "XPRESSBEES",
    partnerServiceId: "105e81fc",
    partnerServiceName: "STANDARD 0.5",
    serviceMode: "SURFACE",
    total: 622.1,
    variables: 0,
    zone: "ZONE 2",
  },
];

export const keyNameMapping = {
  partners: { name: "Partners", label: "service.partnerName" },
  paymentType: { name: "Payment Type", label: "codInfo.isCod" },
  sources: { name: "Sources", label: "source" },
  orderType: { name: "Order Type", label: "orderType" },
  sellerId: { name: "Seller Id", label: "sellerId" },
};
