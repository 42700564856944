import React, { useEffect, useState } from "react";
import PlanCard from "./planCard";
import { Breadcrum } from "../../components/Layout/breadcrum";
import "../../styles/plan.css";
import {
  GET_ALL_PLANS,
  GET_FEATURES_PLANS,
  POST_ASSIGN_PLANV3,
  POST_CREATE_PLAN,
} from "../../utils/ApiUrls";
import { POST } from "../../utils/webService";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import AccessDenied from "../../components/AccessDenied";
import ComparePlans from "./comparePlans";
import { useNavigate } from "react-router-dom";
import CenterModal from "../../components/CustomModal/customCenterModal";
import WebCrossIcon from "../../assets/PickUp/ModalCrossWeb.svg";
import ServiceButton from "../../components/Button/ServiceButton";
import { BottomNavBar } from "../../components/BottomNavBar";
import { checkPageAuthorized } from "../../redux/reducers/role";
import { Spinner } from "../../components/Spinner";
import ToastCustom from "../toastCutom";
import OneButton from "../../components/Button/OneButton";
import FeatureRateCard from "./featureRateCardDetails";

interface ITypeProps {}

const Index = (props: ITypeProps) => {
  const navigate = useNavigate();
  const roles = useSelector((state: any) => state?.roles);

  // const isActive = roles.roles?.[0]?.menu?.[4]?.menu?.[0]?.pages?.[0]?.isActive;
  const isActive = checkPageAuthorized("Plans");

  const [allPlans, setAllPlans] = useState<any>([]);
  const [activePlanId, setActivePlanId] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onSelectPlan, setOnSelectPlan] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [featureRateCardPlan, setFeatureRateCardPlan] = useState([]);

  const ModalContent = () => {
    return (
      <div className="flex flex-col  w-full h-full p-5">
        <div className="flex justify-end">
          <img
            src={WebCrossIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => setIsModalOpen(false)}
          />
        </div>
        <div className="flex flex-col  mt-16">
          <div className="flex flex-col  items-center  ">
            <p className="font-Open text-sm md:text-base font-semibold text-center">
              Are you sure you want to select
              <span className="text-[#004EFF]"> {onSelectPlan?.planName} </span>
              plan?
            </p>
            <div className="flex  items-center gap-x-4 mt-10">
              <OneButton
                text="Yes"
                className=" px-4 py-2"
                onClick={() => {
                  assignPlan(onSelectPlan);
                  setIsModalOpen(false);
                }}
                variant="secondary"
              />
              {/* <ServiceButton
                text="Yes"
                className="bg-[#ffffff] px-4 py-2 text-[#1c1c1c] font-semibold text-sm"
                onClick={() => {
                  assignPlan(onSelectPlan);
                  setIsModalOpen(false);
                }}
              /> */}
              <OneButton
                text="No"
                className=" px-4 py-2"
                onClick={() => setIsModalOpen(false)}
                variant="primary"
              />
              {/* <ServiceButton
                text="No"
                className="bg-[#1C1C1C] px-4 py-2 text-white font-semibold text-sm"
                onClick={() => setIsModalOpen(false)}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const assignPlan = async (payload: any) => {
    try {
      // Assign Plan API
      const { data: response }: any = await POST(POST_ASSIGN_PLANV3, {
        planId: payload?.planId,
      });
      if (response?.success) {
        setActivePlanId(payload?.planId);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      return error;
    }
  };

  const sortByPrice = (a: any, b: any) => {
    return a.price - b.price;
  };

  const callFeaturesRateCard = async () => {
    try {
      const { data: response }: any = await POST(GET_FEATURES_PLANS, {
        limit: 1000000,
      });
      if (response?.success) {
        setFeatureRateCardPlan(response?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      try {
        //Get all plans API
        setLoading(true);
        const { data: response }: any = await POST(GET_ALL_PLANS, {
          limit: 1000000,
        });

        if (response?.success) {
          setLoading(false);
          let tempPlan = response?.data;
          tempPlan.sort(sortByPrice);
          // const selectedPlans = tempPlan.filter((plan: any) => plan.isSelected);
          // const unselectedPlans = tempPlan.filter(
          //   (plan: any) => !plan.isSelected
          // );
          // const reorderedPlans = selectedPlans.concat(unselectedPlans);
          // tempPlan.push({
          //   planId: "123445",
          //   planName: "Enterprise",
          //   validity: "YEARLY",
          //   description:
          //     "Enterprise 12sdfsdfdfsdf fsdfsdfsdf efsdfsdfsdf sfasasd sadasdasd SSDASdsad sadasdas asdasdsa sdasdsad asdasd asdas adsadsadsdsds ",
          //   shortDescription: "Enterprise",
          //   price: 0,
          //   isPublic: true,
          //   rateCards: [],
          //   currency: "INR",
          //   created_At: "2024-05-09T13:10:58.000Z",
          //   updated_At: "2024-05-09T13:11:31.000Z",
          //   isActive: true,
          //   isDeleted: false,
          //   variantId: "5ac217ed-d307-4d4d-a158-6efd2943d507",
          //   isSelected: false,
          // });
          setAllPlans(tempPlan);
          callFeaturesRateCard();
        }
      } catch (error) {
        setLoading(false);
        console.error("GET PLAN API ERROR", error);
        return error;
      }
    })();
  }, [activePlanId]);

  // const featureRateCardData = [
  //   {
  //     rateCardName: "Bronze",
  //     featureRateCard: [
  //       {
  //         featureTitle: "Pre-Shipment",
  //         featureSubMenu: [
  //           {
  //             featureSubTitle: "Catalog Management",
  //             isActive: true,
  //           },
  //           {
  //             featureSubTitle: "AI based Carrier allocation",
  //             isActive: false,
  //           },
  //         ],
  //       },
  //       {
  //         featureTitle: "Order Creation",
  //         featureSubMenu: [
  //           {
  //             featureSubTitle: "Single dashboard for all orders",
  //             isActive: true,
  //           },
  //           {
  //             featureSubTitle: "Real-time serviceability check",
  //             isActive: true,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     rateCardName: "Silver",
  //     featureRateCard: [
  //       {
  //         featureTitle: "Pre-Shipment",
  //         featureSubMenu: [
  //           {
  //             featureSubTitle: "Catalog Management",
  //             isActive: false,
  //           },
  //           {
  //             featureSubTitle: "AI based Carrier allocation",
  //             isActive: true,
  //           },
  //         ],
  //       },
  //       {
  //         featureTitle: "Order Creation",
  //         featureSubMenu: [
  //           {
  //             featureSubTitle: "Single dashboard for all orders",
  //             isActive: true,
  //           },
  //           {
  //             featureSubTitle: "Real-time serviceability check",
  //             isActive: false,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     rateCardName: "Gold",
  //     featureRateCard: [
  //       {
  //         featureTitle: "Pre-Shipment",
  //         featureSubMenu: [
  //           {
  //             featureSubTitle: "Catalog Management",
  //             isActive: true,
  //           },
  //           {
  //             featureSubTitle: "AI based Carrier allocation",
  //             isActive: false,
  //           },
  //         ],
  //       },
  //       {
  //         featureTitle: "Order Creation",
  //         featureSubMenu: [
  //           {
  //             featureSubTitle: "Single dashboard for all orders",
  //             isActive: true,
  //           },
  //           {
  //             featureSubTitle: "Real-time serviceability check",
  //             isActive: true,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     rateCardName: "Platium",
  //     featureRateCard: [
  //       {
  //         featureTitle: "Pre-Shipment",
  //         featureSubMenu: [
  //           {
  //             featureSubTitle: "Catalog Management",
  //             isActive: false,
  //           },
  //           {
  //             featureSubTitle: "AI based Carrier allocation",
  //             isActive: true,
  //           },
  //         ],
  //       },
  //       {
  //         featureTitle: "Order Creation",
  //         featureSubMenu: [
  //           {
  //             featureSubTitle: "Single dashboard for all orders",
  //             isActive: true,
  //           },
  //           {
  //             featureSubTitle: "Real-time serviceability check",
  //             isActive: false,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  return (
    <>
      {/* {<ToastCustom message="hello i am from plan" />} */}
      {isActive ? (
        <div>
          <div className="">
            <div className="">
              <Breadcrum label="Plans" />
            </div>

            {loading ? (
              <div className="flex items-center justify-center w-full h-[40vh]">
                <Spinner />
              </div>
            ) : (
              <>
                {/* Plan Cards */}
                <div
                  className={`px-4 flex items-center  w-full gap-x-20 ${
                    allPlans?.length <= 4 && "lg:justify-center"
                  }  overflow-x-auto`}
                >
                  {allPlans?.map((eachPlan: any, index: any) => {
                    return (
                      <>
                        {eachPlan?.isPublic && (
                          <PlanCard
                            planId={eachPlan?.planId}
                            planName={eachPlan?.planName}
                            price={eachPlan?.price}
                            validity={eachPlan?.validity}
                            description={eachPlan?.description}
                            onClick={() => {
                              setIsModalOpen(true);
                              setOnSelectPlan(eachPlan);
                            }}
                            activePlanId={activePlanId}
                            isSelected={eachPlan?.isSelected}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
                {/*Compare Button */}
                {/* <div className="flex justify-center ml-5  lg:hidden">
                  <ServiceButton
                    text="COMPARE"
                    className="bg-[#1c1c1c] !w-[160px] px-4 py-2 text-[#ffffff] font-semibold text-sm"
                    onClick={() => {
                      navigate("/plans/compare-plans");
                    }}
                  />
                </div> */}
                {/* feature rate card details */}
                {featureRateCardPlan?.length > 0 && (
                  <div className="border-t-2 mt-20 p-6">
                    <h1 className="font-Lato ml-4 text-[#1C1C1C] mb-2 text-[28px] font-semibold">
                      Pricing Structure
                    </h1>
                    <FeatureRateCard
                      featureRateCardData={featureRateCardPlan}
                    />
                  </div>
                )}
                {/* Table */}
                {/* <div className="hidden lg:block">
                  <ComparePlans />
                </div> */}
              </>
            )}
          </div>
          {/* Bottom NavBar */}
          {/* <div className="lg:hidden">
            <BottomNavBar />
          </div> */}
          {/* Modal */}
          <CenterModal
            isOpen={isModalOpen}
            className=" !flex !justify-center !items-center !w-[320px] !h-[320px]  lg:!w-[500px] lg:!h-[320px]"
            onRequestClose={() => setIsModalOpen(false)}
          >
            {ModalContent()}
          </CenterModal>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default Index;
