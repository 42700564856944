import React, { useEffect, useRef, useState } from "react";
import CustomInputBox from "../../../components/Input";
import AddCircleBlack from "../../../assets/add-circle_black.svg";
import MinusCircle from "../../../assets/subtract-circle.svg";
import { POST } from "../../../utils/webService";
import {
  GET_SELLER_ORDER_COMPLETE_DATA,
  POST_SERVICEABILITY,
} from "../../../utils/ApiUrls";
import SelectDateModalContent from "./dateTime";
import CustomButton from "../../../components/Button";
import Checkbox from "../../../components/CheckBox";
import toast from "react-hot-toast";
import { parse } from "date-fns";
import accordianDownIcon from "../../../assets/downwardArrow.svg";
import accordianUpIcon from "../../../assets/AccordionUp.svg";

interface ReverseProps {
  awbData?: any;
  summaryData?: any;
}

const ReverseIndex = (props: ReverseProps) => {
  const { awbData, summaryData } = props;
  const [actualArray, setActualArray] = useState([]);
  const [serviceArray, setServiceArray] = useState<any>();
  const [pickupDate, setPickupDate] = useState("");
  const [productArray, setProductArray]: any = useState();
  const [boxArray, setBoxArray]: any = useState();
  const [pickupAddress, setPickupAddress] = useState({
    contactPerson: "",
    contactNo: "",
    emailId: "",
    buildingName: "",
    locality: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });
  const [deliveryAddress, setDeliveryAddress] = useState({
    contactPerson: "",
    contactNo: "",
    emailId: "",
    buildingName: "",
    locality: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });
  const [restInfo, setRestInfo]: any = useState(); // it only store info and use in reverse order time in payload

  let productsArray: any = [];

  // call reverse service api
  const callReverseServiceAPI = async (responsData: any) => {
    // console.log("🚀 ~ callReverseServiceAPI ~ responsData:", responsData);
    try {
      const payload = {
        pickupPincode: responsData["deliveryAddress"]?.pincode,
        deliveryPincode: responsData["pickupAddress"]?.pincode,
        invoiceValue: responsData?.boxInfo?.[0]?.codInfo?.invoiceValue,
        paymentMode: responsData?.boxInfo?.[0]?.codInfo?.isCod
          ? "COD"
          : "PREPAID",
        // serviceId: "a07d01f5",
        weight: responsData?.boxInfo?.[0]?.appliedWeight,
        orderType: "B2C",
        transit: "REVERSE",
        dimension: {
          length: responsData?.boxInfo?.[0]?.length,
          width: responsData?.boxInfo?.[0]?.breadth,
          height: responsData?.boxInfo?.[0]?.height,
        },
      };
      const response = await POST(POST_SERVICEABILITY, payload);
      setServiceArray(response?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    let awbNo: any = awbData?.data;

    // call service api
    (async () => {
      try {
        const payload = {
          awb: awbNo,
        };
        const response = await POST(GET_SELLER_ORDER_COMPLETE_DATA, payload);
        if (response?.data?.status) {
          let responsData = response?.data?.data?.[0]?.data?.[0];
          setRestInfo(responsData);
          // get all products from box object
          for (let i = 0; i < responsData?.boxInfo?.length; i++) {
            productsArray.push(...responsData?.boxInfo?.[i]?.products);
          }
          let newDataArray: any = [];
          if (responsData["deliveryAddress"]) {
            newDataArray.push({
              title: "Pickup Address",
            });
            setPickupAddress((prevState) => ({
              ...prevState,
              contactPerson: responsData["deliveryAddress"]?.contact?.name,
              contactNo: responsData["deliveryAddress"]?.contact?.mobileNo,
              emailId: responsData["deliveryAddress"]?.contact?.emailId,
              buildingName: responsData["deliveryAddress"]?.fullAddress,
              locality: responsData["deliveryAddress"]?.locality,
              landmark: responsData["deliveryAddress"]?.landmark,
              pincode: responsData["deliveryAddress"]?.pincode,
              city: responsData["deliveryAddress"]?.city,
              state: responsData["deliveryAddress"]?.state,
              country: responsData["deliveryAddress"]?.country,
            }));
          }
          // push Pickup Date and Time array in newDataArray[]
          newDataArray.push({
            title: "Pickup Date and Time",
          });

          if (responsData["pickupAddress"]) {
            newDataArray.push({
              title: "Delivery Address",
            });
            setDeliveryAddress((prevState) => ({
              ...prevState,
              contactPerson: responsData["pickupAddress"]?.contact?.name,
              contactNo: responsData["pickupAddress"]?.contact?.mobileNo,
              emailId: responsData["pickupAddress"]?.contact?.emailId,
              buildingName: responsData["pickupAddress"]?.fullAddress,
              locality: responsData["pickupAddress"]?.locality,
              landmark: responsData["pickupAddress"]?.landmark,
              pincode: responsData["pickupAddress"]?.pincode,
              city: responsData["pickupAddress"]?.city,
              state: responsData["pickupAddress"]?.state,
              country: responsData["pickupAddress"]?.country,
            }));
          }
          if (responsData["boxInfo"]) {
            newDataArray.push({
              title: "Products",
            });

            // let tempArray = [
            //   {
            //     companyId: "00a8dd6f-9a70-497e-b7bd-2c149521fdad",
            //     privateCompanyId: 104486,
            //     sellerId: 5530,
            //     productId: "6b12093e-2d4b-4365-bf22-866cd672537e",
            //     name: "Suresh Pros 1",
            //     category: "Toys & Games",
            //     qty: 5,
            //     sku: "DSFS",
            //     hsnCode: "",
            //     currency: "INR",
            //     unitPrice: 123,
            //     unitTax: 12,
            //     measureUnit: "cm",
            //     length: 1,
            //     breadth: 1,
            //     height: 1,
            //     deadWeight: 4,
            //     weightUnit: "kg",
            //     volumetricWeight: 0,
            //     appliedWeight: 4,
            //     divisor: 5000,
            //     images: [],
            //     selected: true,
            //   },
            //   {
            //     companyId: "00a8dd6f-9a70-497e-b7bd-2c149521fdad",
            //     privateCompanyId: 104486,
            //     sellerId: 5530,
            //     productId: "6b12093e-2d4b-4365-bf22-866cd672537e",
            //     name: "Suresh Pros 2",
            //     category: "Toys & Games",
            //     qty: 4,
            //     sku: "DSFS",
            //     hsnCode: "",
            //     currency: "INR",
            //     unitPrice: 123,
            //     unitTax: 12,
            //     measureUnit: "cm",
            //     length: 1,
            //     breadth: 1,
            //     height: 1,
            //     deadWeight: 4,
            //     weightUnit: "kg",
            //     volumetricWeight: 0,
            //     appliedWeight: 4,
            //     divisor: 5000,
            //     images: [],
            //     selected: true,
            //   },
            //   {
            //     companyId: "00a8dd6f-9a70-497e-b7bd-2c149521fdad",
            //     privateCompanyId: 104486,
            //     sellerId: 5530,
            //     productId: "6b12093e-2d4b-4365-bf22-866cd672537e",
            //     name: "Suresh Pros 3",
            //     category: "Toys & Games",
            //     qty: 3,
            //     sku: "DSFS",
            //     hsnCode: "",
            //     currency: "INR",
            //     unitPrice: 123,
            //     unitTax: 12,
            //     measureUnit: "cm",
            //     length: 1,
            //     breadth: 1,
            //     height: 1,
            //     deadWeight: 4,
            //     weightUnit: "kg",
            //     volumetricWeight: 0,
            //     appliedWeight: 4,
            //     divisor: 5000,
            //     images: [],
            //     selected: true,
            //   },
            // ];

            sessionStorage.setItem(
              "reverseProductArray",
              JSON.stringify(productsArray)
            ); // this are used to store productArray in sessionStorage after state changes useEffect are run agian and set qty value

            setProductArray(productsArray);
          }
          if (responsData["boxInfo"]) {
            newDataArray.push({
              title: "Box",
            });
            setBoxArray(responsData["boxInfo"]);
          }
          // push in array reverse service
          newDataArray.push({
            title: "Reverse Service",
          });

          setActualArray(newDataArray);

          callReverseServiceAPI(responsData);
        } else {
          //services
        }
      } catch (error) {}
    })(); // Immediately invoke the async function
  }, []);

  // this useEffect are used to get from sessionStorage reverseProductArray and accordingly set the default qty value
  useEffect(() => {
    let sessionTemp = JSON.parse(
      sessionStorage.getItem("reverseProductArray") as any
    );

    if (sessionTemp) {
      sessionTemp.forEach((el: any, i: number) => {
        let qtyElements = document.getElementsByClassName(`qtyProduct_${i}`);
        if (qtyElements.length > 0) {
          let qtyElement = qtyElements[0];
          qtyElement.setAttribute("data-actualQty", el.qty); // set attribute and there value
        }
      });
    }
  }, [productArray, actualArray]);

  const handleCheck = (ele: any, index: number) => {
    let tempArr = serviceArray;
    tempArr?.forEach((element: any) => {
      element.isChecked = false;
    });
    tempArr[index].isChecked = true;
    setServiceArray([...tempArr]);

    // if (ele?.target?.checked) {
    //   reverseServiceArrayTemp.push(ele.target.name);
    //   // this function are used to get unique value from array
    //   const uniqueArray = reverseServiceArrayTemp.reduce(
    //     (accumulator: any, currentValue: any) => {
    //       if (!accumulator.includes(currentValue)) {
    //         accumulator.push(currentValue);
    //       }
    //       return accumulator;
    //     },
    //     []
    //   );
    //   setReverseSeviceArray(uniqueArray);
    // } else {
    //   let filterValue = reverseSeviceArray.filter(
    //     (item: any) => item !== ele?.target?.name
    //   );
    //   setReverseSeviceArray(filterValue);
    // }
  };

  const addQtyProduct = (j: any) => {
    let qtyElements: any = document.getElementsByClassName(`qtyProduct_${j}`);
    if (qtyElements.length > 0) {
      // Access the first element in the collection
      let qtyElement = qtyElements[0];

      // Ensure the element has a value and it's a number
      if (qtyElement.value && !isNaN(qtyElement.value)) {
        // Parse the current value as an integer
        let currentQty = parseInt(qtyElement.value, 10);

        //get actual product qty with help data-actualQty
        let getActualQtyElements: any =
          qtyElement.getAttribute(`data-actualQty`);

        // Subtract 1 from the current value
        let newQty = currentQty + 1;

        for (let i = 0; i < productArray?.length; i++) {
          if (i === j) {
            // here we check from tempProductCopyArray object is qty is not greaterthan newQty value
            if (getActualQtyElements < newQty) {
              toast.error("Qty is not update");
            } else {
              qtyElement.value = newQty;
              productArray[j].qty = newQty;
              qtyElement.setAttribute("data-actualQty", getActualQtyElements); // set attribute and there value
            }
            break;
          }
        }
      } else {
        console.error("The value of the element is not a number.");
      }
    } else {
      console.error(`No elements found with the class name qtyProduct_${j}`);
    }
  };

  const minusQtyProduct = (j: any) => {
    let qtyElements: any = document.getElementsByClassName(`qtyProduct_${j}`);

    if (qtyElements.length > 0) {
      // Access the first element in the collection
      let qtyElement = qtyElements[0];

      // Ensure the element has a value and it's a number
      if (qtyElement.value && !isNaN(qtyElement.value)) {
        // Parse the current value as an integer
        let currentQty = parseInt(qtyElement.value, 10);

        //get actual product qty with help data-actualQty
        let getActualQtyElements: any =
          qtyElement.getAttribute(`data-actualQty`);

        // Subtract 1 from the current value
        let newQty = currentQty - 1;

        // let tempProductCopyArray = productCopyArray;

        for (let i = 0; i < productArray?.length; i++) {
          if (newQty >= 0) {
            // here we check from tempProductCopyArray object is qty is not greaterthan newQty value
            // if (tempProductCopyArray[j].qty >= newQty) {
            if (getActualQtyElements >= newQty) {
              qtyElement.value = newQty;
              productArray[j].qty = newQty;
              qtyElement.setAttribute("data-actualQty", getActualQtyElements); // set attribute and there value
            } else {
              toast.error("Qty is not less than 0");
            }
            break;
          } else {
            toast.error(`Qty is not less than zero`);
            break;
          }
        }
      } else {
        console.error("The value of the element is not a number.");
      }
    } else {
      console.error(`No elements found with the class name qtyProduct_${j}`);
    }
  };

  // console.log("🚀 ~ minusQtyProduct ~ productArray:", productArray);

  function convertToEpoch(dateTimeString: any) {
    const parsedDateTime = parse(
      dateTimeString,
      "dd/MM/yyyy hh:mm a",
      new Date()
    );
    return Math.floor(parsedDateTime.getTime());
  }

  const handlePickupTimeSelected = (pickupTime: string) => {
    // console.log("🚀 ~ handlePickupTimeSelected ~ pickupTime:", pickupTime);
    let editedPickupDateForEpoch: any = pickupTime?.substring(0, 19);
    editedPickupDateForEpoch = convertToEpoch(editedPickupDateForEpoch);
    setPickupDate(editedPickupDateForEpoch);
  };

  const handlePickupInputChange = (event: any) => {
    const { name, value } = event.target;
    setPickupAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeliveryInputChange = (event: any) => {
    const { name, value } = event.target;
    setDeliveryAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlerSubmit = () => {
    // to check pickupDate is empty or not
    if (pickupDate === "" || pickupDate === null || pickupDate === undefined) {
      toast.error("Pickup Date And Time Is Missing");
      return;
    }

    // to check atleast one product are have one qty in product array
    const hasProductWithQtyGreaterThanZero = productArray.some(
      (product: any) => product.qty > 0
    );

    // hasProductWithQtyGreaterThanZero it give true or false
    if (!hasProductWithQtyGreaterThanZero) {
      toast.error("Atleast One Product Are Required");
    } else {
      let filterProductQtyZero: any = productArray?.filter(
        (el: any, i: number) => el?.qty != 0
      );

      let summaryTemp: any = {
        pickupAddress: pickupAddress,
        pickupTime: pickupDate,
        deliveryAddress: deliveryAddress,
        productArray: filterProductQtyZero,
        boxArray: boxArray,
        reverseSeviceArray: serviceArray,
        restInfo: restInfo,
      };
      summaryData(summaryTemp);
    }
  };
  return (
    <div className="relative h-[90vh] px-4">
      <div className="flex flex-col gap-2 h-[80%] overflow-auto">
        {actualArray?.map((item: any, i: number) => {
          return (
            <div className="accordionContainerBoxStyle">
              <div
                className={`cursor-pointer px-4 py-3 flex justify-between items-center
                                   ${
                                     item?.isCollapse
                                       ? "bg-[#E8E8E8] rounded-tr-lg rounded-tl-lg border-[1px]"
                                       : "shadow-md rounded "
                                   }`}
                onClick={() => {
                  let temp = [...actualArray];

                  if (item.isCollapse === true) {
                    item.isCollapse = false;
                    setActualArray(temp);
                  } else {
                    item.isCollapse = true;
                    setActualArray(temp);
                  }
                }}
                key={i}
              >
                <div className="flex basis-[90%] items-center gap-x-2">
                  <div className="text-[16px] font-semibold font-Open text-[#1C1C1C] leading-tight">
                    {item?.title}
                  </div>
                </div>
                <div className="flex justify-end items-center gap-x-1">
                  <img
                    src={
                      item.isCollapse === true
                        ? accordianUpIcon
                        : accordianDownIcon
                    }
                    alt=""
                    className="cursor-pointer"
                    onClick={() => {}}
                  />
                </div>
              </div>
              {/* Pickup Address Accordian */}
              {item?.isCollapse && item?.title === "Pickup Address" && (
                <div className="m-5 h-[500]px gap-[10px] flex flex-col overflow-auto border p-[0.5rem]">
                  <div className="flex gap-2 mt-[10px] ">
                    <CustomInputBox
                      label="Contact Person"
                      value={pickupAddress?.contactPerson}
                      name="contactPerson"
                      onChange={handlePickupInputChange}
                    />
                    <CustomInputBox
                      label="Contact No"
                      value={pickupAddress?.contactNo}
                      name="contactNo"
                      onChange={handlePickupInputChange}
                    />
                  </div>
                  <div className="">
                    <CustomInputBox
                      label="Email Id"
                      value={pickupAddress?.emailId}
                      name="emailId"
                      onChange={handlePickupInputChange}
                    />
                  </div>
                  <div className="">
                    <CustomInputBox
                      label="Plot no., floor, building name "
                      value={pickupAddress?.buildingName}
                      name="buildingName"
                      onChange={handlePickupInputChange}
                    />
                  </div>
                  <div className="flex gap-2 ">
                    <CustomInputBox
                      label="Locality"
                      value={pickupAddress?.locality}
                      name="locality"
                      onChange={handlePickupInputChange}
                    />
                    <CustomInputBox
                      label="Landmark"
                      value={pickupAddress?.landmark}
                      name="landmark"
                      onChange={handlePickupInputChange}
                    />
                  </div>
                  <div className="flex gap-2 ">
                    <CustomInputBox
                      label="Pincode"
                      value={pickupAddress?.pincode}
                      name="pincode"
                      onChange={handlePickupInputChange}
                    />
                    <CustomInputBox
                      label="City"
                      value={pickupAddress?.city}
                      name="city"
                      onChange={handlePickupInputChange}
                    />
                  </div>
                  <div className="flex gap-2 ">
                    <CustomInputBox
                      label="State"
                      value={pickupAddress?.state}
                      name="state"
                      onChange={handlePickupInputChange}
                    />
                    <CustomInputBox
                      label="Country"
                      value={pickupAddress?.country}
                      name="country"
                      onChange={handlePickupInputChange}
                    />
                  </div>
                </div>
              )}
              {/* Delivery Address Accordian */}
              {item?.isCollapse && item?.title === "Delivery Address" && (
                <div className="m-5 h-[500]px gap-[10px] flex flex-col overflow-auto border p-[0.5rem]">
                  <div className="flex gap-2 mt-[10px] ">
                    <CustomInputBox
                      label="Contact Person"
                      value={deliveryAddress?.contactPerson}
                      name="contactPerson"
                      onChange={handleDeliveryInputChange}
                    />
                    <CustomInputBox
                      label="Contact No"
                      value={deliveryAddress?.contactNo}
                      name="contactNo"
                      onChange={handleDeliveryInputChange}
                    />
                  </div>
                  <div className="">
                    <CustomInputBox
                      label="Email Id"
                      value={deliveryAddress?.emailId}
                      name="emailId"
                      onChange={handleDeliveryInputChange}
                    />
                  </div>
                  <div className="">
                    <CustomInputBox
                      label="Plot no., floor, building name "
                      value={deliveryAddress?.buildingName}
                      name="buildingName"
                      onChange={handleDeliveryInputChange}
                    />
                  </div>
                  <div className="flex gap-2 ">
                    <CustomInputBox
                      label="Locality"
                      value={deliveryAddress?.locality}
                      name="locality"
                      onChange={handleDeliveryInputChange}
                    />
                    <CustomInputBox
                      label="Landmark"
                      value={deliveryAddress?.landmark}
                      name="landmark"
                      onChange={handleDeliveryInputChange}
                    />
                  </div>
                  <div className="flex gap-2 ">
                    <CustomInputBox
                      label="Pincode"
                      value={deliveryAddress?.pincode}
                      name="pincode"
                      onChange={handleDeliveryInputChange}
                    />
                    <CustomInputBox
                      label="City"
                      value={deliveryAddress?.city}
                      name="city"
                      onChange={handleDeliveryInputChange}
                    />
                  </div>
                  <div className="flex gap-2 ">
                    <CustomInputBox
                      label="State"
                      value={deliveryAddress?.state}
                      name="state"
                      onChange={handleDeliveryInputChange}
                    />
                    <CustomInputBox
                      label="Country"
                      value={deliveryAddress?.country}
                      name="country"
                      onChange={handleDeliveryInputChange}
                    />
                  </div>
                </div>
              )}
              {/* Products Accordian*/}
              {item?.isCollapse && item?.title === "Products" && (
                <div className="m-5 h-[500]px gap-[10px] flex flex-col overflow-auto border p-[0.5rem]">
                  {productArray?.map((ele: any, j: number) => {
                    return (
                      <div className="accordionContainerBoxStyle">
                        <div
                          className={`cursor-pointer px-4 py-3 flex justify-between items-center
                            ${
                              ele?.isCollapse
                                ? "bg-[#E8E8E8] rounded-tr-lg rounded-tl-lg border-[1px]"
                                : "shadow-md rounded "
                            }`}
                          onClick={() => {
                            let temp = [...productArray];

                            if (ele.isCollapse === true) {
                              ele.isCollapse = false;
                              setProductArray(temp);
                            } else {
                              ele.isCollapse = true;
                              setProductArray(temp);
                            }
                          }}
                          key={j}
                        >
                          <div className="flex basis-[90%] items-center gap-x-2">
                            <div className="text-[16px] font-semibold font-Open text-[#1C1C1C] leading-tight">
                              {ele?.name}
                            </div>
                          </div>
                          <div className="flex justify-end items-center gap-x-1">
                            <img
                              src={
                                ele.isCollapse === true
                                  ? accordianUpIcon
                                  : accordianDownIcon
                              }
                              alt=""
                              className="cursor-pointer"
                              onClick={() => {}}
                            />
                          </div>
                        </div>
                        {/* nested accordian expand */}
                        {ele?.isCollapse && (
                          <div className="p-[10px]">
                            <div className="flex justify-between">
                              <p className="font-Open text-[12px] font-semibold self-center">
                                Product Quantity:
                              </p>
                              <div className="flex gap-2">
                                <img
                                  src={MinusCircle}
                                  className="cursor-pointer"
                                  onClick={() => minusQtyProduct(j)}
                                />
                                <input
                                  type="text"
                                  className={`w-[12px] p-0 qtyProduct_${j}`}
                                  data-actualQty={ele?.qty}
                                  value={ele?.qty}
                                />
                                <img
                                  src={AddCircleBlack}
                                  className="cursor-pointer"
                                  onClick={() => addQtyProduct(j)}
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 mb-[10px]">
                              <CustomInputBox
                                label="Dead Weight"
                                value={ele?.deadWeight}
                                isDisabled={true}
                              />
                              <CustomInputBox
                                label="Volumentric Weight"
                                value={ele?.volumetricWeight}
                                isDisabled={true}
                              />
                            </div>
                            <div className="flex gap-2">
                              <CustomInputBox
                                label="Mesurement Unit"
                                value={ele?.measureUnit}
                                isDisabled={true}
                              />
                              <div className="flex gap-2">
                                <CustomInputBox
                                  label="L"
                                  value={ele?.length}
                                  isDisabled={true}
                                />
                                <CustomInputBox
                                  label="B"
                                  value={ele?.breadth}
                                  isDisabled={true}
                                />
                                <CustomInputBox
                                  label="H"
                                  value={ele?.height}
                                  isDisabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {/* Box Accordian*/}
              {item?.isCollapse && item?.title === "Box" && (
                <div className="m-5 h-[500]px gap-[10px] flex flex-col overflow-auto border p-[0.5rem]">
                  {boxArray?.map((ele: any, j: number) => {
                    const boxHandler = (value: any, fieldName: any) => {
                      let temp = [...boxArray];
                      temp[j][fieldName] = value;

                      if (fieldName !== "deadWeight") {
                        // Recalculate volumetric weight based on the dimensions
                        const { length, breadth, height } = temp[j];
                        const volumetricWeight =
                          (length * breadth * height) / 5000;
                        temp[j].volumetricWeight = volumetricWeight.toFixed(2);
                      }

                      setBoxArray(temp);
                    };

                    return (
                      <div className="accordionContainerBoxStyle">
                        <div
                          className={`cursor-pointer px-4 py-3 flex justify-between items-center
                            ${
                              ele?.isCollapse
                                ? "bg-[#E8E8E8] rounded-tr-lg rounded-tl-lg border-[1px]"
                                : "shadow-md rounded "
                            }`}
                          onClick={() => {
                            let temp = [...boxArray];

                            if (ele.isCollapse === true) {
                              ele.isCollapse = false;
                              setBoxArray(temp);
                            } else {
                              ele.isCollapse = true;
                              setBoxArray(temp);
                            }
                          }}
                          key={j}
                        >
                          <div className="flex basis-[90%] items-center gap-x-2">
                            <div className="text-[16px] font-semibold font-Open text-[#1C1C1C] leading-tight">
                              {ele?.name}
                            </div>
                          </div>
                          <div className="flex justify-end items-center gap-x-1">
                            <img
                              src={
                                ele.isCollapse === true
                                  ? accordianUpIcon
                                  : accordianDownIcon
                              }
                              alt=""
                              className="cursor-pointer"
                              onClick={() => {}}
                            />
                          </div>
                        </div>
                        {/* nested accordian expand */}
                        {ele?.isCollapse && (
                          <div className="p-[10px]">
                            <div className="flex gap-2 mb-[10px]">
                              <CustomInputBox
                                label="Dead Weight"
                                value={ele?.deadWeight}
                                // isDisabled={true}
                                onChange={(e) =>
                                  boxHandler(
                                    Number(e.target.value),
                                    "deadWeight"
                                  )
                                }
                              />
                              <CustomInputBox
                                label="Volumentric Weight"
                                value={ele?.volumetricWeight}
                                isDisabled={true}
                              />
                            </div>
                            <div className="flex gap-2">
                              <CustomInputBox
                                label="Mesurement Unit"
                                defaultValue={ele?.measureUnit}
                                isDisabled={true}
                              />
                              <div className="flex gap-2">
                                <CustomInputBox
                                  label="L"
                                  defaultValue={ele?.length}
                                  id="length"
                                  // isDisabled={true}
                                  onChange={(e) =>
                                    boxHandler(Number(e.target.value), "length")
                                  }
                                />
                                <CustomInputBox
                                  label="B"
                                  defaultValue={ele?.breadth}
                                  // isDisabled={true}
                                  id="breadth"
                                  onChange={(e) =>
                                    boxHandler(
                                      Number(e.target.value),
                                      "breadth"
                                    )
                                  }
                                />
                                <CustomInputBox
                                  label="H"
                                  defaultValue={ele?.height}
                                  // isDisabled={true}
                                  id="height"
                                  onChange={(e) =>
                                    boxHandler(Number(e.target.value), "height")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {/* Delivery Address Accordian */}
              {item?.isCollapse && item?.title === "Reverse Service" && (
                <div className="m-5 max-h-[200px] overflow-auto">
                  {serviceArray?.map((el: any, index: number) => {
                    // console.log("🚀 ~ {serviceArray?.map ~ el:", el);
                    return (
                      <div className="flex" key={index}>
                        <Checkbox
                          key={index}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleCheck(event, index);
                          }}
                          name={el?.partnerServiceName}
                          label={""}
                          style={{ accentColor: "black" }}
                          checked={el.isChecked}
                          // checkboxClassName="gap-2 mt-1"
                        />
                        <div className="flex flex-row justify-between p-2 w-full">
                          <div className="flex ">
                            <span className="font-Open text-[14px] mr-1 font-normal text-[#494949]">
                              {el?.partnerServiceName}
                            </span>

                            <span className="font-Open ml-1 text-[14px] font-normal text-[#494949]">
                              : Reverse {el?.serviceMode}
                            </span>
                          </div>
                          <div className="flex font-Open text-[14px] font-normal">
                            <span>₹</span>
                            <span className="ml-0">{el?.total}</span>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </div>
              )}
              {/* Pickup Date and Time Accordian */}
              {item?.isCollapse && item?.title === "Pickup Date and Time" && (
                <SelectDateModalContent
                  onPickupTimeSelected={handlePickupTimeSelected}
                />
              )}
            </div>
          );
        })}
        <div className="absolute w-[93%] bottom-6">
          <CustomButton text={"Next"} onClick={() => handlerSubmit()} />
        </div>
      </div>
    </div>
  );
};

export default ReverseIndex;
