import React, { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomTable } from "../../components/Table";
import UpArrowIcon from "../../assets/upArrorwBlue.svg";
import { capitalizeFirstLetter } from "../../utils/utility";

const RateCardTable = (props: any) => {
  const { serviceData } = props;

  let activeServiceInfo: any = serviceData.filter(
    (serviceObj: any) => serviceObj?.isActive === true
  );

  const formatString = (inputString: any) => {
    // Split the string by underscore
    const words = inputString.split("_");

    // Capitalize the first letter of each word
    const formattedWords = words.map(
      (word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    // Join the words with space
    const formattedString = formattedWords.join(" ");

    return formattedString;
  };

  const columnsHelper = createColumnHelper<any>();
  const columns = [
    columnsHelper.accessor("serviceName", {
      header: (props) => {
        return (
          <div className="flex items-center">
            {/* <PartialChecked
              checked={props.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
              intermediate={props?.table?.getIsSomeRowsSelected()}
            /> */}
            <div>
              <h1 className="text-base font-Open font-semibold leading-5 text-[#1C1C1C] flex justify-center items-center">
                Service Name
              </h1>
            </div>
            {/* <div className="grid grid-cols-3 gap-4">
                            <div className="bg-gray-200 p-4">1</div>
                            <div className="bg-gray-300 p-4">2</div>
                            <div className="bg-gray-400 p-4">3</div>
                            <div className="bg-gray-500 p-4">4</div>
                            <div className="bg-gray-600 p-4">5</div>
                            <div className="bg-gray-700 p-4">6</div>
                        </div> */}
          </div>
        );
      },
      cell: ({ row }: any) => {
        let serviceName = row?.original?.partnerServiceName;
        return (
          <div className="flex items-center whitespace-nowrap my-4">
            {/* <div className="flex justify-center items-center  w-[20px] h-[20px] mr-3 cursor-pointer">
              <input
                type="checkbox"
                checked={row?.getIsSelected()}
                onChange={row?.getToggleSelectedHandler()}
                className="!w-[16px]"
              />
            </div> */}
            <p className="font-Open text-sm font-normal leading-5">
              {formatString(serviceName)}
            </p>
          </div>
        );
      },
    }),
    columnsHelper.accessor("transit", {
      header: () => {
        return (
          <div className="flex justify-center items-center text-center min-w-fit">
            <h1 className="text-base font-Open font-semibold leading-5 ">
              Transit
            </h1>
          </div>
        );
      },
      cell: ({ row, tdClassName }: any) => {
        tdClassName = "";
        let tempTransit = row?.original?.weightSlab;
        let forwardTemp = tempTransit?.filter(
          (el: any, i: any) => el?.transit === "FORWARD"
        );
        let returnTemp = tempTransit?.filter(
          (el: any, i: any) => el?.transit === "RETURN"
        );
        let reverseTemp = tempTransit?.filter(
          (el: any, i: any) => el?.transit === "REVERSE"
        );

        let uniqueValue: any = [];
        return (
          <div className="grid grid-cols-3">
            {tempTransit?.map((data: any, index: number) => {
              if (
                !uniqueValue?.some((obj: any) => obj?.transit === data?.transit)
              ) {
                uniqueValue?.push({
                  transit: data?.transit,
                  inputHtml: data?.inputHtml,
                });
              }
            })}
            <div className="">
              <div className="col-span-1">
                {forwardTemp?.map((el: any, i: number) => {
                  if (i === 0) {
                    return (
                      <>
                        {
                          <>
                            <div className="font-Open font-normal text-sm text-center pt-[10%] pb-[65%]">
                              <div>
                                <div>{capitalizeFirstLetter(el?.transit)}</div>
                              </div>
                            </div>
                          </>
                        }
                      </>
                    );
                  }
                })}
              </div>
              {/* <hr className="mt-2 h-[12px]" /> */}

              {/* {uniqueValue?.length > 1 && <hr className={`h-[3px]`} />} */}
              <div className="col-span-1">
                {returnTemp?.map((el: any, i: number) => {
                  if (i === 0) {
                    return (
                      <>
                        <div className="font-Open font-normal pt-[40%] pb-[65%] text-sm  mt-2 text-center">
                          {capitalizeFirstLetter(el?.transit)}
                        </div>
                        {/* <div>{el?.inputHtml}</div> */}
                      </>
                    );
                  }
                })}
              </div>
              {/* <hr className="mt-2 h-[12px]" /> */}
              {/* {uniqueValue?.length > 2 && <hr className={`h-[3px]`} />} */}
              <div className="col-span-1">
                {reverseTemp?.map((el: any, i: number) => {
                  if (i === 0) {
                    return (
                      <>
                        <div className="font-Open font-normal pt-[50%] pb-[15%] text-sm  mt-2 text-center">
                          {capitalizeFirstLetter(el?.transit)}
                        </div>
                        {/* <div>{el?.inputHtml}</div> */}
                      </>
                    );
                  }
                })}
              </div>
              {/* <hr className="mt-2 h-[12px]" /> */}
            </div>
          </div>
        );
      },
    }),
    columnsHelper.accessor("weightSlab", {
      header: () => {
        return (
          <div className="flex justify-center items-center text-center min-w-fit">
            <h1 className="text-base font-Open font-semibold leading-5 ">
              Weight Range Slab (Kgs)
            </h1>
          </div>
        );
      },
      cell: ({ row }: any) => {
        let tempOriginal = row?.original;
        let tempTransit = row?.original?.weightSlab;
        let uniqueValue: any = [];

        tempTransit?.map((data: any, index: number) => {
          if (
            !uniqueValue?.some((obj: any) => obj?.transit === data?.transit)
          ) {
            uniqueValue?.push({
              transit: data?.transit,
              inputHtml: data?.inputHtml,
            });
          }
        });

        return (
          <div>
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "FORWARD"
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data?.from}
                          defaultValue={data?.from || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      To{" "}
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data?.to}
                          defaultValue={data?.to || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length > 1 && <hr className="h-[12px] mt-2" />}
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "RETURN"
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data?.from}
                          defaultValue={data?.from || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      To{" "}
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data?.to}
                          defaultValue={data?.to || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length >= 3 && <hr className="h-[12px] mt-2" />}
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "REVERSE"
              ) {
                return (
                  <div className="leading-10 ">
                    <div className="flex gap-2">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data?.from}
                          defaultValue={data?.from || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      To{" "}
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data?.to}
                          defaultValue={data?.to || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      },
    }),
    columnsHelper.accessor("incrementalWeight", {
      header: () => {
        return (
          <div className="flex justify-center items-center text-center min-w-fit">
            <h1 className="text-base font-Open font-semibold leading-5 ">
              Incremental Chargeable Weight (Kgs)
            </h1>
          </div>
        );
      },
      cell: ({ row }: any) => {
        let tempOriginal = row?.original;
        let tempTransit = row?.original?.weightSlab;
        let uniqueValue: any = [];

        tempTransit?.map((data: any, index: number) => {
          if (
            !uniqueValue?.some((obj: any) => obj?.transit === data?.transit)
          ) {
            uniqueValue?.push({
              transit: data?.transit,
              inputHtml: data?.inputHtml,
            });
          }
        });

        return (
          <div>
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "FORWARD"
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5 justify-center text-center itmes-center">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          id={`incremental${index}`}
                          // value={data?.weight}
                          defaultValue={data?.weight || ""}
                          style={{
                            color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length > 1 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "RETURN"
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5 justify-center text-center itmes-center">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          id={`incremental${index}`}
                          // value={data?.weight}
                          defaultValue={data?.weight || ""}
                          style={{
                            color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length >= 3 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "REVERSE"
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5 justify-center items-center itmes-center">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          id={`incremental${index}`}
                          // value={data?.weight}
                          defaultValue={data?.weight || ""}
                          style={{
                            color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      },
    }),
    columnsHelper.accessor("zone1", {
      header: () => {
        return (
          <div className="flex justify-center items-center text-center min-w-fit">
            <h1 className="font-semibold font-Open text-base leading-5">
              Zone 1
            </h1>
          </div>
        );
      },
      cell: ({ row }: any) => {
        let tempOriginal = row?.original;
        let tempTransit = row?.original?.weightSlab;
        let uniqueValue: any = [];

        tempTransit?.map((data: any, index: number) => {
          if (
            !uniqueValue?.some((obj: any) => obj?.transit === data?.transit)
          ) {
            uniqueValue?.push({
              transit: data?.transit,
              inputHtml: data?.inputHtml,
            });
          }
        });

        return (
          <div>
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "FORWARD" &&
                data["ZONE 1"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 1"]?.base}
                          defaultValue={data["ZONE 1"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 1"]?.add}
                          defaultValue={data["ZONE 1"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length > 1 && <hr className="h-[12px] mt-2" />}
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "RETURN" &&
                data["ZONE 1"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 1"]?.base}
                          defaultValue={data["ZONE 1"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 1"]?.add}
                          defaultValue={data["ZONE 1"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length >= 3 && <hr className="h-[12px] mt-2" />}
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "REVERSE" &&
                data["ZONE 1"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 1"]?.base}
                          defaultValue={data["ZONE 1"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 1"]?.add}
                          defaultValue={data["ZONE 1"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      },
    }),
    columnsHelper.accessor("zone2", {
      header: () => {
        return (
          <div className="flex justify-center items-center text-center min-w-fit ">
            <h1 className="font-semibold font-Open text-base leading-5">
              Zone 2
            </h1>
          </div>
        );
      },
      cell: ({ row }: any) => {
        let tempOriginal = row?.original;
        let tempTransit = row?.original?.weightSlab;
        let uniqueValue: any = [];

        tempTransit?.map((data: any, index: number) => {
          if (
            !uniqueValue?.some((obj: any) => obj?.transit === data?.transit)
          ) {
            uniqueValue?.push({
              transit: data?.transit,
              inputHtml: data?.inputHtml,
            });
          }
        });

        return (
          <div>
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "FORWARD" &&
                data["ZONE 2"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C]  ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 2"]?.base}
                          defaultValue={data["ZONE 2"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 2"]?.add}
                          defaultValue={data["ZONE 2"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length > 1 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "RETURN" &&
                data["ZONE 2"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 2"]?.base}
                          defaultValue={data["ZONE 2"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 2"]?.add}
                          defaultValue={data["ZONE 2"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length >= 3 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "REVERSE" &&
                data["ZONE 2"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 2"]?.base}
                          defaultValue={data["ZONE 2"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 2"]?.add}
                          defaultValue={data["ZONE 2"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      },
    }),
    columnsHelper.accessor("zone3", {
      header: () => {
        return (
          <div className="flex justify-center items-center text-center min-w-fit ">
            <h1 className="text-base font-semibold font-Open leading-5">
              Zone 3
            </h1>
          </div>
        );
      },
      cell: ({ row }: any) => {
        let tempOriginal = row?.original;
        let tempTransit = row?.original?.weightSlab;
        let uniqueValue: any = [];

        tempTransit?.map((data: any, index: number) => {
          if (
            !uniqueValue?.some((obj: any) => obj?.transit === data?.transit)
          ) {
            uniqueValue?.push({
              transit: data?.transit,
              inputHtml: data?.inputHtml,
            });
          }
        });

        return (
          <div>
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "FORWARD" &&
                data["ZONE 3"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C]  ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 3"]?.base}
                          defaultValue={data["ZONE 3"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 3"]?.add}
                          defaultValue={data["ZONE 3"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length > 1 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "RETURN" &&
                data["ZONE 3"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 3"]?.base}
                          defaultValue={data["ZONE 3"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 3"]?.add}
                          defaultValue={data["ZONE 3"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length >= 3 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "REVERSE" &&
                data["ZONE 3"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 3"]?.base}
                          defaultValue={data["ZONE 3"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 3"]?.add}
                          defaultValue={data["ZONE 3"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      },
    }),
    columnsHelper.accessor("zone4", {
      header: () => {
        return (
          <div className="flex justify-center items-center text-center min-w-fit ">
            <h1 className="text-base font-semibold font-Open leading-5">
              Zone 4
            </h1>
          </div>
        );
      },
      cell: ({ row }: any) => {
        let tempOriginal = row?.original;
        let tempTransit = row?.original?.weightSlab;
        let uniqueValue: any = [];

        tempTransit?.map((data: any, index: number) => {
          if (
            !uniqueValue?.some((obj: any) => obj?.transit === data?.transit)
          ) {
            uniqueValue?.push({
              transit: data?.transit,
              inputHtml: data?.inputHtml,
            });
          }
        });

        return (
          <div>
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "FORWARD" &&
                data["ZONE 4"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 4"]?.base}
                          defaultValue={data["ZONE 4"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 4"]?.add}
                          defaultValue={data["ZONE 4"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length > 1 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "RETURN" &&
                data["ZONE 4"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 4"]?.base}
                          defaultValue={data["ZONE 4"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 4"]?.add}
                          defaultValue={data["ZONE 4"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length >= 3 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "REVERSE" &&
                data["ZONE 4"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 4"]?.base}
                          defaultValue={data["ZONE 4"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 4"]?.add}
                          defaultValue={data["ZONE 4"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      },
    }),
    columnsHelper.accessor("zone5", {
      header: () => {
        return (
          <div className="flex justify-center items-center text-center min-w-fit ">
            <h1 className="text-base font-semibold font-Open leading-5">
              Zone 5
            </h1>
          </div>
        );
      },
      cell: ({ row }: any) => {
        let tempOriginal = row?.original;
        let tempTransit = row?.original?.weightSlab;
        let uniqueValue: any = [];

        tempTransit?.map((data: any, index: number) => {
          if (
            !uniqueValue?.some((obj: any) => obj?.transit === data?.transit)
          ) {
            uniqueValue?.push({
              transit: data?.transit,
              inputHtml: data?.inputHtml,
            });
          }
        });

        return (
          <div>
            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "FORWARD" &&
                data["ZONE 5"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 5"]?.base}
                          defaultValue={data["ZONE 5"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 5"]?.add}
                          defaultValue={data["ZONE 5"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length > 1 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "RETURN" &&
                data["ZONE 5"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 5"]?.base}
                          defaultValue={data["ZONE 5"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 5"]?.add}
                          defaultValue={data["ZONE 5"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
            {uniqueValue?.length >= 3 && <hr className="h-[12px] mt-2" />}

            {tempOriginal?.weightSlab?.map((data: any, index: number) => {
              if (
                data?.hasOwnProperty("transit") &&
                data?.transit === "REVERSE" &&
                data["ZONE 5"]
              ) {
                return (
                  <div className="leading-10">
                    <div className="flex gap-2 pr-5">
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 5"]?.base}
                          defaultValue={data["ZONE 5"]?.base || ""}
                          style={{
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <div>
                        <span className="border-[1px] border-[#000000]"></span>
                      </div>
                      <div>
                        <input
                          className={`h-[30px] w-[50px] p-[11px] cursor-not-allowed border-none font-Open text-sm font-normal leading-5 text-[#1C1C1C] ${tempOriginal?.partnerServiceId}_editSingleRow`}
                          type="text"
                          // value={data["ZONE 5"]?.add}
                          defaultValue={data["ZONE 5"]?.add || ""}
                          style={{
                            // color: "#004EFF",
                            background: "#F3F3F3",
                          }}
                          readOnly
                        />
                      </div>
                      <img src={UpArrowIcon} alt="" />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      },
    }),
  ];

  return <CustomTable data={activeServiceInfo || []} columns={columns} />;
};

export default RateCardTable;
